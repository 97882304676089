<template>
  <section>
    <router-view></router-view>
  </section>
</template>
<script>
  export default {
    name: 'configuracionContratos',
    setup () {
      return {
      }
    }
  }
</script>
<style scoped>
</style>
